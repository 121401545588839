/**
 * Provides utility functions to check if a user has specific permissions.
 *
 * @returns An object containing the following functions:
 *   - hasBriefingPermission: Function to check if a user has permission to access a briefing.
 *   - hasPlanningPermission: Function to check if a user has permission to access planning information.
 */
export const usePermissions = () => {

  const { userData, isSuperadmin, isAdmin } = useUserState()

  function hasBriefingPermission(obj:FsbdBriefingCreate) {
    if (isSuperadmin || isAdmin) {
      return true
    } else {
      if (obj.refTypology?.value === 'public') {
        return true
      } else if (obj.refTypology?.value === 'restricted' && obj.companies?.map((el:any) => el.refCompany?.name).includes(userData.value.refCompany?.name)) {
        return true
      }
    }
    return false
  }

  function hasPlanningPermission(obj: FsbdPlanning) {
    if (isSuperadmin || isAdmin) {
      return true
    } else {
      if (obj.refCompany.name === userData.value.refCompany?.name) {
        return true
      }
    }
    return false
  }

  function hasInsightPermission(obj: FsbdInsight) {
    if (isSuperadmin || isAdmin) {
      return true
    } else {
      if (obj.refCompany.name === userData.value.refCompany?.name) {
        return true
      }
    }
    return false
  }

  function hasInvoicePermission(obj: FsbdInvoice) {
    if (isSuperadmin || isAdmin) {
      return true
    } else {
      if (obj.refCompany.name === userData.value.refCompany?.name) {
        //return true
      }
    }
    return false
  }

  return {
    hasBriefingPermission,
    hasPlanningPermission,
    hasInsightPermission,
    hasInvoicePermission
  }
}



